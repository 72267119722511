import { Box } from '@trmediaab/zebra';
import { cloudinary } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';

import { userType } from '~/main/prop-types';

import Card from '../Card';
import DefinitionList from '../DefinitionList';
import Query from '../Query';
import query from './_GetJokerDetails.gql';

const InfoItem = ({ value, title }) => (
  <DefinitionList.listItem title={title}>{value || ''}</DefinitionList.listItem>
);

InfoItem.propTypes = {
  value: PropTypes.node,
  title: PropTypes.string.isRequired,
};

const SummaryCard = ({ user }) => (
  <Query
    query={query}
    options={{ variables: { id: user.id } }}
    wrap={content => (
      <Card
        title="Joker Live"
        footer={<Card.footerLink href="/joker" title="Profilinställningar" />}
      >
        {content}
      </Card>
    )}
    render={({ user }) => (
      <DefinitionList>
        <InfoItem title="Alias" value={user.jokerProfile?.alias} />
        <InfoItem
          title="Bild"
          value={
            user.jokerProfile?.image && (
              <Box
                as="img"
                alt="preview"
                src={cloudinary.getUrl(user.jokerProfile.image, 160, [1, 1])}
                maxWidth="80px"
              />
            )
          }
        />
      </DefinitionList>
    )}
  />
);

SummaryCard.propTypes = {
  user: userType.isRequired,
};

export default SummaryCard;
