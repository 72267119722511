import AccountSummary from '~/components/AccountDetails/SummaryCard';
import CardStack from '~/components/CardStack';
import JokerSummary from '~/components/JokerDetails/SummaryCard';
import NavLayout from '~/components/Layout/NavLayout';
import LoginSummary from '~/components/LoginDetails/SummaryCard';
import OrdersSummary from '~/components/Orders/SummaryCard';
import PageTitle from '~/components/PageTitle';
import PaymentMethodsSummary from '~/components/PaymentMethods/SummaryCard';
import Subscriptions from '~/components/Subscriptions';
import TicketsSummary from '~/components/Tickets/SummaryCard';
import useAuthUser from '~/main/useAuthUser';

const IndexPage = () => {
  const user = useAuthUser();

  return (
    <>
      <PageTitle title="Mina sidor" />
      <CardStack>
        <Subscriptions user={user} />
        <AccountSummary user={user} />
        <LoginSummary user={user} />
        <OrdersSummary user={user} />
        <TicketsSummary user={user} />
        <PaymentMethodsSummary user={user} />
        {user.hasAccessedJokersystemet && <JokerSummary user={user} />}
      </CardStack>
    </>
  );
};

IndexPage.getLayout = page => (
  <NavLayout
    title="Mitt konto"
    subTitle="Välkommen till Mina sidor. Här har du möjlighet att göra uppdateringar kopplat till dina personliga uppgifter och prenumerationer. Observera att vi har en sektion som heter Frågor och Svar. Där har du möjlighet att hitta svar på eventuella frågor eller problem. Du hittar sidan i huvudmenyn här ovanför."
  >
    {page}
  </NavLayout>
);

export default IndexPage;
