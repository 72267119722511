import Image from 'next/image';
import fallback from 'payment-icons/min/flat/default.svg';
import maestro from 'payment-icons/min/flat/maestro.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import visa from 'payment-icons/min/flat/visa.svg';
import PropTypes from 'prop-types';

const creditCardsMap = {
  visa,
  mastercard,
  maestro,
};

const CreditCard = ({ brand, width }) => {
  const normalizedBrand = brand.toLowerCase();
  const src = creditCardsMap[normalizedBrand] || fallback;

  return <Image src={src} width={width} alt={normalizedBrand} />;
};

CreditCard.propTypes = {
  brand: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export default CreditCard;
