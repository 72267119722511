import { FlexBox, Text } from '@trmediaab/zebra';
import PropTypes from 'prop-types';

const DefinitionList = ({ children }) => (
  <FlexBox as="dl" flexDirection="row" flexWrap="wrap">
    {children}
  </FlexBox>
);

DefinitionList.propTypes = { children: PropTypes.node.isRequired };

const ListItem = ({ title, children, responsive = true }) => (
  <>
    <Text
      as="dt"
      flex={responsive ? ['1 0 100%', null, '1 0 20%'] : '1 0 20%'}
      color="greys.3"
      fontWeight="semiBold"
      textTransform="uppercase"
      mt={responsive ? ['2,5', null, 1] : 1}
      sx={{
        '&:first-of-type': {
          mt: 0,
        },
      }}
    >
      {title}
    </Text>
    <Text
      as="dd"
      flex={responsive ? ['1 0 100%', null, '1 0 80%'] : '1 0 80%'}
      mt={responsive ? [0, null, 1] : 1}
      sx={{
        '&:first-of-type': {
          mt: 0,
        },
      }}
    >
      {children}
    </Text>
  </>
);

ListItem.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  responsive: PropTypes.bool,
};

DefinitionList.listItem = ListItem;

export default DefinitionList;
