import PropTypes from 'prop-types';

import { userType } from '~/main/prop-types';

import Address from '../Address';
import Card from '../Card';
import DefinitionList from '../DefinitionList';
import Query from '../Query';
import query from './_GetAccountDetails.gql';

const AccountItem = ({ value, title }) =>
  value == null || value === '' ? null : (
    <DefinitionList.listItem title={title}>{value}</DefinitionList.listItem>
  );

AccountItem.propTypes = {
  value: PropTypes.node,
  title: PropTypes.string.isRequired,
};

const SummaryCard = ({ user }) => (
  <Query
    query={query}
    options={{ variables: { id: user.id } }}
    wrap={content => (
      <Card
        title="Kontouppgifter"
        footer={<Card.footerLink href="/konto" title="Ändra dina uppgifter" />}
      >
        {content}
      </Card>
    )}
    render={({ getUser }) => {
      const { name, mobile, customerNumber } = getUser;
      const formattedAddress = <Address user={getUser} />;

      return (
        <DefinitionList>
          <AccountItem value={name} title="Namn" />
          <AccountItem value={formattedAddress} title="Address" />
          <AccountItem value={mobile} title="Telefon" />
          <AccountItem value={user.email} title="E-post" />
          <AccountItem value={customerNumber} title="Kundnummer" />
        </DefinitionList>
      );
    }}
  />
);

SummaryCard.propTypes = {
  user: userType.isRequired,
};

export default SummaryCard;
