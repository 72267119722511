import { Box, FlexBox, List, Text } from '@trmediaab/zebra';
import { Time } from '@trmediaab/zebra-components';
import { paymentNetworks } from 'trhub-utils';

import getSubsQuery from '~/components/Subscriptions/_GetSubscriptions.gql';
import useAuthUser from '~/main/useAuthUser';
import availableOrganizations from '~/utils/availableOrganizations';

import Card from '../Card';
import CreditCard from '../CreditCard';
import Query from '../Query';
import query from './_GetUserCards.gql';
import OrganizationHeader from './OrganizationHeader';
import sortCards from './sortCards';

const SummaryCard = () => {
  const user = useAuthUser();

  return (
    <Card
      footer={
        <Card.footerLink
          href="/betalning"
          title="Hantera dina betalningsmetoder"
        />
      }
    >
      <Query
        query={getSubsQuery}
        options={{ variables: { user: user.id } }}
        render={subsData => (
          <List gap="4">
            {availableOrganizations(subsData).map(organization => (
              <List.listItem key={organization}>
                <OrganizationHeader organization={organization} />
                <Query
                  query={query}
                  options={{
                    variables: { user: user.id, organization },
                  }}
                  render={data => {
                    const cards = sortCards(data.cards);

                    if (cards.length === 0) {
                      return <Text pt="3">Inga sparade kort</Text>;
                    }

                    return (
                      <List gap="3" mt="3">
                        {cards.map(card => (
                          <List.listItem key={card.id}>
                            <FlexBox>
                              <Box mr="3" flexShrink="0" width="40px" pt="3px">
                                <CreditCard width={40} brand={card.brand} />
                              </Box>
                              <Box>
                                <Text fontWeight="semiBold" lineHeight="medium">
                                  {paymentNetworks[card.brand]} som slutar med{' '}
                                  {card.maskedPan.slice(-4)}
                                </Text>
                                <Text color="greys.3">
                                  Utgår:{' '}
                                  <Time
                                    date={new Date(card.expiryDate)}
                                    format="MM/yyyy"
                                  />
                                </Text>
                                {card.isDefault && (
                                  <Text
                                    color="green"
                                    fontSize="1"
                                    fontWeight="bold"
                                  >
                                    FÖRVALT
                                  </Text>
                                )}
                              </Box>
                            </FlexBox>
                          </List.listItem>
                        ))}
                      </List>
                    );
                  }}
                />
              </List.listItem>
            ))}
          </List>
        )}
      />
    </Card>
  );
};

export default SummaryCard;
