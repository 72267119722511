import { Anchor, BorderBox, Button, Position, Text } from '@trmediaab/zebra';
import PropTypes from 'prop-types';
import { usePopperTooltip } from 'react-popper-tooltip';

import Help from '~/icons/Help';

const Tooltip = ({ text, url }) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    delayHide: 250,
    interactive: true,
    offset: [0, 8],
  });

  return (
    <>
      <Button ref={setTriggerRef}>
        <Help color="greys.3" size={['15px', null, '18px']} />
      </Button>

      {visible && (
        <BorderBox
          bg="white"
          borderRadius="1"
          boxShadow="2"
          maxWidth="248px"
          p="3"
          css={`
            &[data-popper-placement^='top'] > :last-child {
              bottom: -4px;
            }
            &[data-popper-placement^='bottom'] > :last-child {
              top: -4px;
            }
            &[data-popper-placement^='left'] > :last-child {
              right: -4px;
            }
            &[data-popper-placement^='right'] > :last-child {
              left: -4px;
            }
          `}
          ref={setTooltipRef}
          {...getTooltipProps()}
        >
          <Text fontSize="1" textAlign="center">
            <Text.p mb="2">{text}</Text.p>
            <Anchor
              href={url}
              target="_blank"
              rel="noopener"
              variant="matchText"
              textDecoration="underline"
            >
              Läs mer
            </Anchor>
          </Text>
          <Position.absolute
            size="8px"
            background="inherit"
            css={`
              visibility: hidden;

              &,
              &::before {
                width: 8px;
                height: 8px;
                background: inherit;
              }

              &::before {
                content: '';
                position: absolute;
                visibility: visible;
                transform: rotate(45deg);
              }
            `}
            {...getArrowProps()}
          />
        </BorderBox>
      )}
    </>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Tooltip;
