import { userType } from '~/main/prop-types';

import Card from '../Card';
import DefinitionList from '../DefinitionList';

const SummaryCard = ({ user }) => (
  <Card
    title="Inloggningsuppgifter"
    footer={
      <Card.footerLink
        href="/inloggning"
        title="Ändra användarnamn och lösenord"
      />
    }
  >
    <DefinitionList>
      <DefinitionList.listItem title="E-post">
        {user.email}
      </DefinitionList.listItem>
      <DefinitionList.listItem title="Lösenord">
        ••••••••••••••••••••
      </DefinitionList.listItem>
    </DefinitionList>
  </Card>
);

SummaryCard.propTypes = {
  user: userType.isRequired,
};

export default SummaryCard;
