import { List } from '@trmediaab/zebra';

import { userType } from '~/main/prop-types';

import Card from '../Card';
import CancelledSubscriptionInfo from '../ManageSubscription/CancelledSubscriptionInfo';
import Query from '../Query';
import query from './_GetSubscriptions.gql';
import subscriptionBrands from './subscriptionBrands';
import SubscriptionProduct from './SubscriptionProduct';

const parseSubscriptionData = (data, user) => {
  const { subscriptions: activeSubscriptions } = data.getSubscriptionsByUser;

  const { products: subscriptionProducts } = data.getProducts;

  const availableSubscriptionSites = new Set();

  for (const product of subscriptionProducts) {
    if (
      activeSubscriptions.some(
        ({ product: { site: activeSite } }) => activeSite === product.site,
      )
    ) {
      continue;
    }
    availableSubscriptionSites.add(product.site);
  }

  // TODO - show banprogram?
  return [
    // List of active subscriptions but show only those where the product site is
    // defined in the brand list
    activeSubscriptions.filter(
      subscription => subscriptionBrands[subscription.product.site] != null,
    ),
    // List of subscriptions the user doesn't have
    // - show only those where the product site is defined in the brand list
    // - show Joker Norway only if the user has NO country
    // - show Joker Sweden only if the user does not have NO country
    [...availableSubscriptionSites]

      .filter(site => subscriptionBrands[site] != null)
      .filter(
        site => site !== 'jokersystemet_no' || user.address?.country === 'NO',
      )
      .filter(
        site => site !== 'jokersystemet' || user.address?.country !== 'NO',
      )
      .sort((a, b) => a.localeCompare(b)),
  ];
};

const Subscriptions = ({ user }) => (
  <Query
    query={query}
    options={{ variables: { user: user.id } }}
    wrap={(content, type) =>
      type === 'data' ? (
        content
      ) : (
        <Card title="Aktiva prenumerationer">{content}</Card>
      )
    }
    render={data => {
      const [activeSubscriptions, availableSubscriptionSites] =
        parseSubscriptionData(data, user);

      return (
        <>
          <Card title="Aktiva prenumerationer">
            {activeSubscriptions.length === 0 ? (
              'Du har inga aktiva prenumerationer'
            ) : (
              <List divided gap="3,5">
                {activeSubscriptions.map(sub => (
                  <List.listItem key={sub.id}>
                    <SubscriptionProduct
                      subscriptionId={sub.id}
                      site={sub.product.site}
                      subscriptionLabel={sub.product.name}
                      upgradeEnabled={false}
                      payee={sub.payee}

                      /* 
                        Postponed: https://github.com/trmediaab/trhub/issues/368#issuecomment-879039409
                        sub.unsubscribeDate == null && productIsUpgradeable 
                      */
                    />
                    {sub.unsubscribeDate != null && (
                      <CancelledSubscriptionInfo subscription={sub} mt="4" />
                    )}
                  </List.listItem>
                ))}
              </List>
            )}
          </Card>
          {availableSubscriptionSites.length > 0 && (
            <Card title="Skaffa prenumeration">
              <List divided gap="3,5">
                {availableSubscriptionSites.map(site => (
                  <List.listItem key={site}>
                    <SubscriptionProduct
                      site={site}
                      user={user}
                      subscriptionLabel="Du har ingen prenumeration"
                    />
                  </List.listItem>
                ))}
              </List>
            </Card>
          )}
        </>
      );
    }}
  />
);

Subscriptions.propTypes = {
  user: userType.isRequired,
};

export default Subscriptions;
