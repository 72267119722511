import { useWindowEvents } from '@trmediaab/zebra-hooks';
import { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

const useResponsiveLayout = getLayout => {
  const { breakpoints } = useTheme();

  const [layout, setLayout] = useState(getLayout(breakpoints));

  const onWindowResize = useCallback(() => {
    const nextLayout = getLayout(breakpoints);
    if (nextLayout !== layout) {
      setLayout(nextLayout);
    }
  }, [breakpoints, getLayout, layout]);

  useWindowEvents('resize', onWindowResize);

  return layout;
};

export default useResponsiveLayout;
