import { Box, Button, FlexBox, Text } from '@trmediaab/zebra';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { sites } from 'trhub-utils';

import { DoneRound } from '~/icons/Done';

import subscriptionBrands from './subscriptionBrands';
import Tooltip from './Tooltip';

const SubscriptionProduct = ({
  subscriptionId,
  site,
  subscriptionLabel,
  upgradeEnabled = false,
  payee = {
    collectionPayee: false,
  },
}) => (
  <FlexBox alignItems={['start', null, 'center']}>
    <DoneRound
      size="34px"
      bg={subscriptionId != null ? 'green' : 'greys.2'}
      strokeColor="white"
      mr="3"
    />
    <FlexBox
      flex="1"
      flexDirection={['column', null, 'row']}
      alignItems={['start', null, 'center']}
    >
      <Box mr="2">
        <FlexBox alignItems="center">
          <Text
            fontSize={[1, null, 3]}
            fontWeight="bold"
            lineHeight="medium"
            textTransform="uppercase"
            mr={[1, null, 2]}
          >
            {sites[site]}
          </Text>
          <Tooltip
            text={subscriptionBrands[site].text}
            url={subscriptionBrands[site].readmore}
          />
        </FlexBox>
        {subscriptionLabel}
      </Box>
      <FlexBox
        ml={{ md: 'auto' }}
        flexShrink="0"
        flexDirection={{
          _: 'column',
          md: 'row',
          lg: 'column',
          xl: 'row',
        }}
      >
        {payee.collectionPayee ? (
          <Text
            lineHeight="medium"
            mt={{
              _: 3,
              md: 0,
            }}
          >
            <Text.em fontSize="1">Betalas av</Text.em>
            <br />
            {payee.name}
          </Text>
        ) : subscriptionId != null ? (
          <>
            {upgradeEnabled && (
              <Button
                bc="secondary"
                href={subscriptionBrands[site].upgrade}
                target="_blank"
                rel="noopener"
                mt={{
                  _: 3,
                  md: 0,
                }}
              >
                Uppgradera
              </Button>
            )}

            <Button
              as={Link}
              href={`/prenumeration/${subscriptionId}`}
              bc="primary"
              mt={{
                _: 3,
                md: 0,
                lg: 3,
                xl: 0,
              }}
              ml={{
                md: 3,
                lg: 0,
                xl: 3,
              }}
            >
              Hantera prenumeration
            </Button>
          </>
        ) : (
          <Button
            bc="primary"
            href={subscriptionBrands[site].readmore}
            target="_blank"
            rel="noopener"
            mt={{
              _: 3,
              md: 0,
              lg: 3,
              xl: 0,
            }}
          >
            Läs mer
          </Button>
        )}
      </FlexBox>
    </FlexBox>
  </FlexBox>
);

SubscriptionProduct.propTypes = {
  subscriptionId: PropTypes.string,
  site: PropTypes.string.isRequired,
  subscriptionLabel: PropTypes.string.isRequired,
  payee: PropTypes.shape({
    name: PropTypes.string,
    collectionPayee: PropTypes.bool,
  }),
  upgradeEnabled: PropTypes.bool,
};

export default SubscriptionProduct;
