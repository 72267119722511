import { sites } from 'trhub-utils';

import { userType } from '~/main/prop-types';

import Card from '../Card';
import DefinitionList from '../DefinitionList';
import Query from '../Query';
import query from './_GetTickets.gql';

const SummaryCard = ({ user }) => (
  <Query
    query={query}
    pageSize={3}
    options={{ variables: { id: user.id } }}
    wrap={content => (
      <Card
        title="Klippkort"
        footer={
          <Card.footerLink href="/klippkort" title="Visa alla klippkort" />
        }
      >
        {content}
      </Card>
    )}
    render={({ getTicketsByUser: { tickets } }) => {
      if (tickets.length === 0) {
        return 'Inga aktiva klippkort att visa';
      }

      const ticketsToDisplay = tickets.slice(0, 3);

      return (
        <DefinitionList>
          {ticketsToDisplay.map(ticket => (
            <DefinitionList.listItem key={ticket.id} title={ticket.remaining}>
              {sites[ticket.site]} {ticket.betType.join(', ')}
            </DefinitionList.listItem>
          ))}
        </DefinitionList>
      );
    }}
  />
);

SummaryCard.propTypes = {
  user: userType.isRequired,
};

export default SummaryCard;
