import { brands } from '@trmediaab/zebra-utils';

// TODO add env variables for all brand domains
const subscriptionBrands = {
  jokersystemet: {
    ...brands.getBrand('jokersystemet'),
    upgrade: `${process.env.NEXT_PUBLIC_JOKERSYSTEMET_URL}/abonnemang`,
    readmore: `${process.env.NEXT_PUBLIC_JOKERSYSTEMET_URL}/om-joker`,
  },
  jokersystemet_no: {
    ...brands.getBrand('jokersystemet_no'),
    upgrade: `${process.env.NEXT_PUBLIC_JOKERSYSTEMET_NO_URL}/abonnemang`,
    readmore: `${process.env.NEXT_PUBLIC_JOKERSYSTEMET_NO_URL}/om-joker`,
  },
  spelvarde: {
    ...brands.getBrand('spelvarde'),
    upgrade: 'https://www.spelvarde.se/handla',
    readmore: 'https://www.spelvarde.se/om-spelvarde',
  },
  storavinster: {
    ...brands.getBrand('storavinster'),
    upgrade: 'https://www.storavinster.se/handla',
    readmore: 'https://www.storavinster.se/om-oss',
  },
  travfakta: {
    ...brands.getBrand('travfakta'),
    upgrade: 'https://www.travfakta.se/',
    readmore: 'https://www.storavinster.se/om-oss',
  },
  travronden: {
    ...brands.getBrand('travronden'),
    upgrade: 'https://www.travronden.se/prenumerera',
    readmore: 'https://www.travronden.se/fakta',
  },
  travogat: {
    ...brands.getBrand('travogat'),
    upgrade: 'https://www.travogat.se/',
    readmore: 'https://www.travogat.se/om-travogat',
  },
  travronden_spel: {
    ...brands.getBrand('travronden_spel'),
    upgrade: 'https://www.travrondenspel.se/prenumerera',
    readmore: 'https://www.travrondenspel.se/om/travronden-spel',
  },
};

export default subscriptionBrands;
