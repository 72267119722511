import { useMutation } from '@apollo/client';
import { BorderBox, Box, Button, Text } from '@trmediaab/zebra';
import { Time } from '@trmediaab/zebra-components';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import { subscriptionListItemType, subscriptionType } from '~/main/prop-types';
import useAuthUser from '~/main/useAuthUser';

import Confirm from '../Confirm';
import mutation from './_ReactivateSubscription.gql';

const CancelledSubscriptionInfo = ({ subscription, ...styleProps }) => {
  const { id, endDate, paymentMethod } = subscription;
  const [confirmVisible, showConfirm] = useState(false);
  const { id: userId } = useAuthUser();

  const [action] = useMutation(mutation);

  const onReactivateClick = useCallback(() => showConfirm(true), []);
  const onReactivateConfirm = useCallback(async () => {
    showConfirm(false);
    await action({
      variables: {
        id,
        user: userId,
      },
    });
  }, [action, id, userId]);
  const onReactivateCancel = useCallback(() => showConfirm(false), []);

  return (
    <BorderBox
      border="1"
      borderRadius="1"
      borderColor="state.error"
      bg="state.errorBackground"
      p="3"
      {...styleProps}
    >
      <Text.strong>
        Din prenumeration är uppsagd och upphör att gälla{' '}
        <Time date={new Date(endDate)} />.
      </Text.strong>{' '}
      Du kan fortsätta att använda tjänsten fram tills det datumet.
      {paymentMethod !== 'free' && (
        <Box mt="3">
          <Button
            onClick={onReactivateClick}
            color="link.primary"
            textDecoration="underline"
          >
            Ångra uppsägning
          </Button>
        </Box>
      )}
      {confirmVisible && (
        <Confirm
          title="Har du ångrat dig?"
          text="Du har fortfarande möjlighet att återuppta din uppsagda prenumeration genom att klicka på knappen nedan."
          confirmLabel="Återuppta prenumeration"
          onConfirm={onReactivateConfirm}
          onCancel={onReactivateCancel}
        />
      )}
    </BorderBox>
  );
};

CancelledSubscriptionInfo.propTypes = {
  subscription: PropTypes.oneOfType([
    subscriptionType,
    subscriptionListItemType,
  ]).isRequired,
};

export default CancelledSubscriptionInfo;
