import { Time } from '@trmediaab/zebra-components';

import { userType } from '~/main/prop-types';

import Card from '../Card';
import DefinitionList from '../DefinitionList';
import Query from '../Query';
import query from './_GetOrders.gql';

const SummaryCard = ({ user }) => (
  <Query
    query={query}
    pageSize={3}
    options={{ variables: { id: user.id } }}
    wrap={content => (
      <Card
        title="Senaste köp"
        footer={<Card.footerLink href="/kop" title="Visa alla köp" />}
      >
        {content}
      </Card>
    )}
    render={({ getOrdersByUser: { orders } }) => {
      if (orders.length === 0) {
        return 'Du har inte gjort några köp';
      }

      const ordersToDisplay = orders.slice(0, 3);

      return (
        <DefinitionList>
          {ordersToDisplay.map(order => (
            <DefinitionList.listItem
              key={order.id}
              title={<Time date={new Date(order.created)} />}
            >
              {order.label}
            </DefinitionList.listItem>
          ))}
        </DefinitionList>
      );
    }}
  />
);

SummaryCard.propTypes = {
  user: userType.isRequired,
};

export default SummaryCard;
